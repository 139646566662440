import React from 'react';

export default function PriceCard({ name, pricePerNight, interval, minDays }) {
  return (
    <div class="w-full
                p-5
                flex flex-col justify-center items-center
                space-y-5
                border-2 border-gray-200
                rounded
    ">
      <div class="text-green-600 text-lg uppercase font-semibold">{ name }</div>
      <div class="text-gray-500">Ab <b>{ minDays }</b> Übernachtungen</div>
      <div>
        <span class="text-5xl font-light">{ pricePerNight }</span>
        <span>€ / Nacht</span>
      </div>
      <div>
        { interval }
      </div>
    </div>
  )
}
