import React from 'react'
import Slider from 'react-slick'

export default function ImageSlider({ children }) {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500
  };

  return (
    <div>
      <link async rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"/> 
      <link async rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css" />

      <Slider class="w-full" {...settings}>
        { children }
      </Slider>
    </div>
  )
}
