import React from 'react'

export default function PriceFeature({ children }) {
  return (
    <div class="w-full md:w-1/2
                flex flex-row
                items-center justify-start
                space-x-2">
      <svg class="fill-current text-green-600 h-8 w-8 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
      <div class="font-medium">{ children }</div>
    </div>
  )
}
