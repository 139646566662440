import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import CallToAction from '../components/call-to-action'
import Feature from '../components/feature'
import Layout from '../components/layout'
import PriceCard from '../components/price-card'
import PriceFeature from '../components/price-feature'
import ImageSlider from '../components/image-slider'

export const sliderImage = graphql`
  fragment sliderImage on File {
    childImageSharp {
      fluid(maxWidth: 1200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "pictures/haus.jpg" }) {
      ...sliderImage
    }
    landlords: file(relativePath: { eq: "pictures/herbert_und_doris.jpg" }) {
      childImageSharp {
        fixed(width: 332, height: 332) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    slider1: file(relativePath: { eq: "pictures/haus/IMG_6142-HDR.jpg" }) {
      ...sliderImage
    }
    slider2: file(relativePath: { eq: "pictures/haus/IMG_6166-HDR.jpg" }) {
      ...sliderImage
    }
    slider3: file(relativePath: { eq: "pictures/haus/IMG_6172-HDR.jpg" }) {
      ...sliderImage
    }
    slider4: file(relativePath: { eq: "pictures/haus/IMG_6202-HDR.jpg" }) {
      ...sliderImage
    }
    slider5: file(relativePath: { eq: "pictures/haus/IMG_6359-HDR.jpg" }) {
      ...sliderImage
    }
  }
`

export default function Home({ data }) {
  return (
    <Layout title="Startseite" backgroundImage={data.bannerImage.childImageSharp.fluid} >
      <div class="w-full flex flex-col space-y-16">
        <div class="w-full flex flex-col md:flex-row">
          <div class="w-full md:w-1/2
                      flex flex-col
                      text-justify
                      space-y-4">
            <p class="text-4xl font-light">Herzlich Willkommen in Einruhr <span role="img" aria-label="waving hand">👋</span></p>
            <p>
              Die Ferienwohnung Mediterran liegt mitten in Einruhr in der Eifel. Durch die große Fülle der Blumen- und
              Pflanzenwelt des Mittelmeerraums, kommt direkt ein mediterranes Gefühl auf, was auch der Name des Hauses
              wiederspiegelt.
            </p>
            <p>
              Die ruhig gelegene, geschmackvoll eingerichtete 4-Sterne Ferienwohnung für Nichtraucher liegt in direkter Nähe
              zum Rursee im Herzen des Nationalpark Eifel. Der noch recht junge Nationalpark bietet Entdeckern viele zugängliche
              Wanderwege für jedes Alter. Neben den gut ausgezeichneten Wanderwegen gibt es in Einruhr einen einfachen 
              Einstieg in die Etappen-Wanderwege "Wildnis-Trail" und "Eifelsteig". Auch mit dem Fahrrad können viele
              Ecken, wie zum Beispiel die Urftseetalsperre, erreicht werden. In direkter Nähe befindet sich außerdem der
              Vennbahnradweg (RAVeL-Route), der als Premium-Radweg durch Ostbelgien und die Nordeifel verläuft.
              Weitere Ausflugsziele sind die ehemalige NS-Ordensburg Vogelsang und die historische Altstadt Monschau,
              welche nur wenige Minuten mit dem Auto entfernt liegt. Die an Einruhr angrenzenden Täler laden ebenfalls
              zum Schlendern oder Verweilen ein.
            </p>
            <p>
              Die gesamte Wohnung befindet sich im Souterrain unseres Einfamilien-Wohnhauses und besitzt nach
              hinten heraus eine schöne große Sonnenterrasse mit 60m² und eine zusätzliche Sonnenfrühstücks-Ecke, die
              sowohl morgens zum gemeinsamen Frühstücken einlädt, als auch Abends den Tag beim Sonnenuntergang ausklingen
              lässt. Dabei kann direkt der freie Blick auf das wundervolle Bergpanorama und den Rursee genossen werden.
            </p>
            <div class="self-center mt-8">
              <CallToAction text="Kontakt"/>
            </div>
          </div>
          <div class="w-full md:w-1/2
                      flex flex-col
                      justify-start items-center
                      space-y-4 mt-8 md:mt-0">
            <Img className="rounded-full" fixed={data.landlords.childImageSharp.fixed} alt="Herbert und Doris Nießen"/>
            <span class="text-xl text-center font-light">
              <p>Ihre Gastgeber</p>
              <p>Herbert und Doris Nießen</p>
            </span>
          </div>
        </div>
        <div>
          <ImageSlider>
            <Img fluid={data.slider1.childImageSharp.fluid} alt="Schlafzimmer"/>
            <Img fluid={data.slider2.childImageSharp.fluid} alt="Badezimmer"/>
            <Img fluid={data.slider3.childImageSharp.fluid} alt="Wohnbereich"/>
            <Img fluid={data.slider4.childImageSharp.fluid} alt="Küche"/>
            <Img fluid={data.slider5.childImageSharp.fluid} alt="Terrasse"/>
          </ImageSlider>
        </div>
        <div class="flex flex-col md:flex-row items-start">
          <div class="w-full md:w-1/2
                      flex flex-col
                      justify-center items-start
                      space-y-6">
            <span class="text-2xl">Ausstattung</span>
            <div class="w-full flex flex-row flex-wrap space-y-2">
              <Feature>2 Personen</Feature>
              <Feature>1 Schlafzimmer</Feature>
              <Feature>55m² Ferienwohnung</Feature>
              <Feature>Große Terasse</Feature>
              <Feature>PKW-Parkplatz</Feature>
            </div>
            <div class="w-full flex flex-row flex-wrap space-y-2">
              <span class="w-full text-lg">Technik</span>
              <Feature>Kostenloses WLAN</Feature>
              <Feature>Fernseher mit Smart-TV Funktion</Feature>
              <Feature>Satelliten-TV</Feature>
              <Feature>CD/DVD-Player</Feature>
            </div>
            <div class="w-full flex flex-row flex-wrap space-y-2">
              <span class="w-full text-lg">Badezimmer</span>
              <Feature>Haartrockner</Feature>
              <Feature>Ebenerdige Dusche</Feature>
              <Feature>Handtücher</Feature>
            </div>
            <div class="w-full flex flex-row flex-wrap space-y-2">
              <span class="w-full text-lg">Küche</span>
              <Feature>Ceran Kochfeld</Feature>
              <Feature>Mikrowelle</Feature>
              <Feature>Wasserkocher</Feature>
              <Feature>Kaffeemaschine (Filter, Pads u. Kapseln)</Feature>
              <Feature>Spülmaschine</Feature>
              <Feature>Toaster</Feature>
              <Feature>Kühlschrank</Feature>
              <Feature>Küchenutensilien</Feature>
              <Feature>Reinigungsmittel</Feature>
            </div>
            <div class="w-full flex flex-row flex-wrap space-y-2">
              <span class="w-full text-lg">Sprachen</span>
              <Feature>Deutsch</Feature>
              <Feature>Niederländisch</Feature>
            </div>
            <div></div>
            <div class="w-full flex flex-col space-y-2">
              <span class="w-full text-lg">Bewertungen</span>
              <p>
                Nach Ihrem Besuch bei uns, würden wir uns sehr über eine Bewertung von Ihnen auf Google Maps freuen.
              </p>
              <a href="https://goo.gl/maps/ZvCM5Vm3Tc68CYYn8" target="_blank" rel="noreferrer"
                 class="rounded-full uppercase bg-yellow-400 px-5 py-2 font-bold text-white self-center">
                Jetzt bewerten
              </a>
            </div>
          </div>
          <div class="w-full md:w-1/2
                      flex flex-col
                      justify-start items-start
                      space-y-4 mt-8 md:mt-0">
            <span class="text-2xl">Lage</span>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2533.3241765508437!2d6.3816189169226645!3d50.58392767949392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf79feb5216919%3A0xdca451dbc2ec958f!2sFerienwohnung%20Mediterran!5e0!3m2!1sde!2sde!4v1609162321251!5m2!1sde!2sde"
                    class="rounded"
                    style={{ height: '400px' }}
                    title="Ferienwohnung Mediterran auf Google Maps"
                    width="100%" height="100%"
                    frameBorder="0" allowFullScreen=""
                    aria-hidden="false">
            </iframe>

            <div></div>

            <span class="text-2xl">Preise</span>
            <div class="w-full flex flex-col md:flex-row justify-center items-center space-y-5 md:space-y-0 md:space-x-5">
              <PriceCard interval="01.11. - 31.03." name="Nebensaison" pricePerNight="79" minDays="2"/>
              <PriceCard interval="01.04. - 31.10." name="Hauptsaison" pricePerNight="79" minDays="6"/>
            </div>
            
            <div class="w-full flex flex-wrap flex-col md:flex-row">
              <PriceFeature>2 Personen</PriceFeature>
              <PriceFeature>Endreinigung: 37€</PriceFeature>
              <PriceFeature>Anzahlung 150€</PriceFeature>
              <PriceFeature>An-/Abreise Samstag u. Sonntag</PriceFeature>
            </div>

            <iframe class="self-center w-full h-96" frameborder="0" src="https://api.belegungskalender-kostenlos.de/kalender.php?kid=36913"
                    title="Externer Buchungskalender der Ferienwohnung Mediterran">
            </iframe>

            <span class="self-center text-sm">
              Der Belegungskalender wird verwaltet von <a href="https://www.belegungskalender-kostenlos.de/anmeldung.php" rel="nofollow">belegungskalender-kostenlos.de</a>
            </span>

            <div class="self-center mt-8">
              <CallToAction text="Jetzt anfragen"/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
